.Container {
  margin-top: 56px;
  padding: 0 var(--g-spacing-4);
  padding-top: var(--g-spacing-4);
}

.AddShipmentForm {
  color: var(--text);

  & input,
  & select {
    flex: 1;
    box-sizing: border-box;
  }

  & label {
    display: block;
    margin: 0 0 5px 0;
  }

  & input,
  & select {
    height: 38px;
    padding: 6px 0;
    color: inherit;
    background-color: var(--background);
    border: 0;
    border-bottom: 1px solid var(--g-colors-material-grey-300);
    border-bottom: 1px solid var(--separator);
    border-radius: 0;
    box-shadow: none;
    box-sizing: border-box;
    font-size: 16px;
    font-family: inherit;
    outline: none;
    transition-property: border, padding;
    transition-duration: 180ms;
    transition-timing-function: ease-in-out;
    -webkit-appearance: none;
    -webkit-border-radius: 0px;
  }

  & input::placeholder {
    color: var(--g-colors-material-grey-600);
  }

  & select {
    color: var(--g-colors-material-grey-600);
  }

  & input:focus,
  & select:focus {
    padding-bottom: 5px;
    border-bottom: 2px solid var(--g-colors-material-blue-400);
  }
}

.hasCarrier select {
  color: inherit;
}

.FormField {
  margin: 0 0 1em;
  display: flex;
  align-items: center;

  & svg {
    margin-right: 32px;
    font-size: 24px;
    fill: var(--symbol);
  }
}

.SubmitButton {
  & svg {
    fill: var(--action-bar-icon-disabled);
  }
}

.formIsComplete {
  & svg {
    fill: var(--action-bar-icon);
  }
}
