.Container {
  margin-top: 56px;
  padding: 0 var(--g-spacing-4);
  padding-top: var(--g-spacing-4);

  & h1 {
    margin: 0;
    font-size: var(--g-text-5xl-font-size);
    line-height: var(--g-text-5xl-line-height);
  }
}

.LastRefresh {
  font-size: var(--g-text-sm-font-size);
  color: var(--g-colors-material-grey-400);
  text-align: center;
}

.Header {
  color: var(--text);

  & h2 {
    margin: 0;
    font-size: var(--g-text-4xl-font-size);
    line-height: var(--g-text-4xl-line-height);
  }

  & p {
    margin: 0;
    font-size: var(--g-text-lg-font-size);
    line-height: var(--g-text-lg-line-height);
    margin-top: var(--g-spacing-2);
    margin-bottom: var(--g-spacing-8);
  }
}
