.App {
  --accent-color: var(--g-colors-material-pink-500);
  --background: #ffffff;
  --card-background: #ffffff;
  --card-background-hover: rgba(0, 0, 0, 0.05);
  --card-background-active: rgba(0, 0, 0, 0.1);
  --card-header-footer: var(--g-colors-material-grey-700);
  --card-title: var(--g-colors-material-grey-900);
  --card-text: var(--g-colors-material-grey-800);
  --card-icon: var(--g-colors-material-grey-800);
  --card-border: var(--g-colors-material-grey-300);
  --action-bar-background: #ffffff;
  --action-bar-title: var(--g-colors-material-grey-900);
  --action-bar-icon: var(--g-colors-material-grey-900);
  --action-bar-icon-disabled: var(--g-colors-material-grey-400);
  --action-bar-button-hover: rgba(0, 0, 0, 0.05);
  --action-bar-button-active: rgba(0, 0, 0, 0.1);
  --add-shipment-button: var(--accent-color);
  --elevation-shadow: 180, 180, 180;
  --details-time: var(--g-colors-material-grey-600);
  --details-location: var(--g-colors-material-grey-500);
  --text: #444444;
  --link: var(--g-colors-material-blue-800);
  --separator: var(--g-colors-material-grey-300);
  --symbol: var(--g-colors-material-grey-800);
  --shimmer: var(--g-colors-material-grey-200);
  --shimmer-background: var(--g-colors-material-grey-100);
  --placeholder-background-color: rgba(255, 255, 255, 0.05);

  background-color: var(--background);
}

.darkTheme {
  --background-hue: 0deg;
  --background-sat: 0%;
  --background-lum: 7%;
  --background: hsl(
    var(--background-hue),
    var(--background-sat),
    var(--background-lum)
  );
  --card-background: hsl(
    var(--background-hue),
    var(--background-sat),
    calc(var(--background-lum) + 10%)
  );
  --card-background-hover: hsl(
    var(--background-hue),
    var(--background-sat),
    calc(var(--background-lum) + 15%)
  );
  --card-background-active: hsl(
    var(--background-hue),
    var(--background-sat),
    calc(var(--background-lum) + 20%)
  );
  --card-header-footer: var(--g-colors-material-grey-200);
  --card-title: #ffffff;
  --card-text: var(--g-colors-material-grey-50);
  --card-icon: var(--g-colors-material-grey-400);
  --card-border: #000000;
  --action-bar-background: var(--background);
  --action-bar-title: #ffffff;
  --action-bar-icon: var(--g-colors-material-grey-400);
  --action-bar-icon-disabled: var(--g-colors-material-grey-800);
  --action-bar-button-hover: hsl(
    var(--background-hue),
    var(--background-sat),
    calc(var(--background-lum) + 10%)
  );
  --action-bar-button-active: hsl(
    var(--background-hue),
    var(--background-sat),
    calc(var(--background-lum) + 15%)
  );
  --elevation-shadow: rgb(0, 0, 0);
  --text: #ffffff;
  --link: var(--g-colors-material-grey-600);
  --separator: var(--g-colors-material-grey-800);
  --symbol: var(--g-colors-material-grey-400);
  --shimmer: rgba(255, 255, 255, 0.15);
  --shimmer-background: rgba(255, 255, 255, 0.05);
}

.darkBlueTheme {
  --background-hue: 210deg;
  --background-sat: 34%;
  --background-lum: 13%;
  --accent-color: var(--g-colors-material-blue-A200);
}

.Container {
  min-height: 100vh;
  max-width: 760px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}
