:root {
  --g-spacing-0_5: 0.125rem;
  --g-spacing-1: 0.25rem;
  --g-spacing-1_5: 0.375rem;
  --g-spacing-2: 0.5rem;
  --g-spacing-2_5: 0.625rem;
  --g-spacing-3: 0.75rem;
  --g-spacing-3_5: 0.875rem;
  --g-spacing-4: 1rem;
  --g-spacing-5: 1.25rem;
  --g-spacing-6: 1.5rem;
  --g-spacing-7: 1.75rem;
  --g-spacing-8: 2rem;

  --g-text-xs-font-size: 0.75rem;
  --g-text-xs-line-height: 1rem;
  --g-text-sm-font-size: 0.875rem;
  --g-text-sm-line-height: 1.25rem;
  --g-text-base-font-size: 1rem;
  --g-text-base-line-height: 1.5rem;
  --g-text-lg-font-size: 1.125rem;
  --g-text-lg-line-height: 1.75rem;
  --g-text-xl-font-size: 1.25rem;
  --g-text-xl-line-height: 1.75rem;
  --g-text-2xl-font-size: 1.5rem;
  --g-text-2xl-line-height: 2rem;
  --g-text-3xl-font-size: 1.875rem;
  --g-text-3xl-line-height: 2.25rem;
  --g-text-4xl-font-size: 2.25rem;
  --g-text-4xl-line-height: 2.5rem;
  --g-text-5xl-font-size: 3rem;
  --g-text-5xl-line-height: 1;

  --g-colors-material-grey-50: #fafafa;
  --g-colors-material-grey-100: #f5f5f5;
  --g-colors-material-grey-200: #eeeeee;
  --g-colors-material-grey-300: #e0e0e0;
  --g-colors-material-grey-400: #bdbdbd;
  --g-colors-material-grey-500: #9e9e9e;
  --g-colors-material-grey-600: #757575;
  --g-colors-material-grey-700: #616161;
  --g-colors-material-grey-800: #424242;
  --g-colors-material-grey-900: #212121;

  --g-colors-material-blue-400: #42a5f5;
  --g-colors-material-blue-800: #1565c0;
  --g-colors-material-blue-900: #0d47a1;
  --g-colors-material-blue-A200: #448aff;
  --g-colors-material-blue-A700: #2962ff;

  --g-colors-material-pink-400: #ec407a;
  --g-colors-material-pink-500: #e91e63;

  --g-colors-google-blue: #4285f4;

  --g-colors-flatui-red: #fc5c65;
  --g-colors-flatui-orange: #fd9644;
  --g-colors-flatui-yellow: #fed330;
  --g-colors-flatui-green: #26de81;
  --g-colors-flatui-blue-1: #2bcbba;
  --g-colors-flatui-blue-2: #45aaf2;
  --g-colors-flatui-blue-3: #4b7bec;
  --g-colors-flatui-purple: #a55eea;
  --g-colors-flatui-gray: #d1d8e0;
  --g-colors-flatui-gray-dark: #778ca3;
}

html {
  color-scheme: light dark;
}

body {
  margin: 0;
  padding: 0;
  min-height: 100vh;
  font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue",
    Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

button,
input {
  font-family: inherit;
}
