.prose ul {
  list-style: none;
}

.prose {
  color: var(--text);
  max-width: 65ch;
  margin-top: 56px;
  padding: 0 16px;
}

.prose:last-of-type {
  margin-bottom: 30vh;
}

.prose [class~="lead"] {
  color: var(--text);
  font-size: 1.25em;
  line-height: 1.6;
  margin-top: 1.2em;
  margin-bottom: 1.2em;
}

.prose a {
  color: var(--accent-color);
  text-decoration: underline;
}

.prose strong {
  color: var(--accent-color);
  font-weight: 600;
}

.prose ol {
  counter-reset: list-counter;
  margin-top: 1.25em;
  margin-bottom: 1.25em;
}

.prose ol > li {
  position: relative;
  counter-increment: list-counter;
  padding-left: 1.75em;
}

.prose ol > li::before {
  content: counter(list-counter) ".";
  position: absolute;
  font-weight: 400;
  color: #718096;
}

.prose ul > li {
  position: relative;
  padding-left: 1.75em;
}

.prose ul > li::before {
  content: "";
  position: absolute;
  background-color: #cbd5e0;
  border-radius: 50%;
  width: 0.375em;
  height: 0.375em;
  top: calc(0.875em - 0.1875em);
  left: 0.25em;
}

.prose hr {
  border-color: #e2e8f0;
  border-top-width: 1px;
  margin-top: 3em;
  margin-bottom: 3em;
}

.prose blockquote {
  font-weight: 500;
  font-style: italic;
  color: var(--accent-color);
  border-left-width: 0.25rem;
  border-left-color: #e2e8f0;
  quotes: "\201C""\201D""\2018""\2019";
  margin-top: 1.6em;
  margin-bottom: 1.6em;
  padding-left: 1em;
}

.prose blockquote p:first-of-type::before {
  content: open-quote;
}

.prose blockquote p:last-of-type::after {
  content: close-quote;
}

.prose h1 {
  color: var(--accent-color);
  font-weight: 800;
  font-size: 2.25em;
  margin-top: 0;
  margin-bottom: 0.8888889em;
  line-height: 1.1111111;
}

.prose h2 {
  color: var(--accent-color);
  font-weight: 700;
  font-size: 1.5em;
  margin-top: 2em;
  margin-bottom: 1em;
  line-height: 1.3333333;
}

.prose h3 {
  color: var(--accent-color);
  font-weight: 600;
  font-size: 1.25em;
  margin-top: 1.6em;
  margin-bottom: 0.6em;
  line-height: 1.6;
}

.prose h4 {
  color: var(--accent-color);
  font-weight: 600;
  margin-top: 1.5em;
  margin-bottom: 0.5em;
  line-height: 1.5;
}

.prose figure figcaption {
  color: #718096;
  font-size: 0.875em;
  line-height: 1.4285714;
  margin-top: 0.8571429em;
}

.prose code {
  color: var(--accent-color);
  font-weight: 600;
  font-size: 0.875em;
}

.prose code::before {
  content: "`";
}

.prose code::after {
  content: "`";
}

.prose pre {
  color: #e2e8f0;
  background-color: #2d3748;
  overflow-x: auto;
  font-size: 0.875em;
  line-height: 1.7142857;
  margin-top: 1.7142857em;
  margin-bottom: 1.7142857em;
  border-radius: 0.375rem;
  padding-top: 0.8571429em;
  padding-right: 1.1428571em;
  padding-bottom: 0.8571429em;
  padding-left: 1.1428571em;
}

.prose pre code {
  background-color: transparent;
  border-width: 0;
  border-radius: 0;
  padding: 0;
  font-weight: 400;
  color: inherit;
  font-size: inherit;
  font-family: inherit;
  line-height: inherit;
}

.prose pre code::before {
  content: "";
}

.prose pre code::after {
  content: "";
}

.prose table {
  width: 100%;
  table-layout: auto;
  text-align: left;
  margin-top: 2em;
  margin-bottom: 2em;
  font-size: 0.875em;
  line-height: 1.7142857;
}

.prose thead {
  color: var(--accent-color);
  font-weight: 600;
  border-bottom-width: 1px;
  border-bottom-color: #cbd5e0;
}

.prose thead th {
  vertical-align: bottom;
  padding-right: 0.5714286em;
  padding-bottom: 0.5714286em;
  padding-left: 0.5714286em;
}

.prose tbody tr {
  border-bottom-width: 1px;
  border-bottom-color: #e2e8f0;
}

.prose tbody tr:last-child {
  border-bottom-width: 0;
}

.prose tbody td {
  vertical-align: top;
  padding-top: 0.5714286em;
  padding-right: 0.5714286em;
  padding-bottom: 0.5714286em;
  padding-left: 0.5714286em;
}

.prose {
  font-size: 1rem;
  line-height: 1.75;
}

.prose p {
  margin-top: 1.25em;
  margin-bottom: 1.25em;
}

.prose img {
  margin-top: 2em;
  margin-bottom: 2em;
}

.prose video {
  margin-top: 2em;
  margin-bottom: 2em;
}

.prose figure {
  margin-top: 2em;
  margin-bottom: 2em;
}

.prose figure > * {
  margin-top: 0;
  margin-bottom: 0;
}

.prose h2 code {
  font-size: 0.875em;
}

.prose h3 code {
  font-size: 0.9em;
}

.prose ul {
  margin-top: 1.25em;
  margin-bottom: 1.25em;
}

.prose li {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}

.prose ol > li:before {
  left: 0;
}

.prose > ul > li p {
  margin-top: 0.75em;
  margin-bottom: 0.75em;
}

.prose > ul > li > *:first-child {
  margin-top: 1.25em;
}

.prose > ul > li > *:last-child {
  margin-bottom: 1.25em;
}

.prose > ol > li > *:first-child {
  margin-top: 1.25em;
}

.prose > ol > li > *:last-child {
  margin-bottom: 1.25em;
}

.prose ul ul,
.prose ul ol,
.prose ol ul,
.prose ol ol {
  margin-top: 0.75em;
  margin-bottom: 0.75em;
}

.prose hr + * {
  margin-top: 0;
}

.prose h2 + * {
  margin-top: 0;
}

.prose h3 + * {
  margin-top: 0;
}

.prose h4 + * {
  margin-top: 0;
}

.prose thead th:first-child {
  padding-left: 0;
}

.prose thead th:last-child {
  padding-right: 0;
}

.prose tbody td:first-child {
  padding-left: 0;
}

.prose tbody td:last-child {
  padding-right: 0;
}

.prose > :first-child {
  margin-top: 0;
}

.prose > :last-child {
  margin-bottom: 0;
}
