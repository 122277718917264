.Container {
  margin-top: 56px;
  padding: 0;
  flex: 1;
  color: var(--text);

  & a {
    text-decoration: none;
  }

  & ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  & li {
    border-bottom: 1px solid var(--separator);
    display: flex;
    align-items: center;
  }

  & a {
    color: inherit;
    flex: 1;
    padding: 21px 0 21px 16px;
  }
}

.ThemeContainer {
  padding: 16px;
  & span {
    flex: 1;
  }
}
