.Container {
  border-left: 2px dashed var(--separator);
  margin-left: 16px;
}

.TrackingHistoryListItem {
  position: relative;
  padding-left: 16px;
  margin-bottom: 24px;
  color: var(--text);
}

.TrackingHistoryListItem:before {
  content: "";
  position: absolute;
  top: 6px;
  left: -7px;
  width: 8px;
  height: 8px;
  border-radius: 10px;
  background-color: var(--background);
  border: 2px solid var(--accent-color);
}

.TrackingHistoryListItem:first-of-type:before {
  background-color: var(--accent-color);
}

.TrackingHistoryListItem h2 {
  margin: 0;
  margin-bottom: 8px;
  font-size: 18px;
}

.TrackingHistoryListItem p {
  margin: 0;
  color: var(--details-location);
}

.Error {
  color: var(--text);
}
