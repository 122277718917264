.Container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: var(--g-spacing-5);
  border-radius: 8px;
  padding: var(--g-spacing-4);

  & h3 {
    margin: 0;
    font-size: inherit;
  }
}
