.Container {
  margin-top: 56px;
  padding: 0;
  flex: 1;
  color: white;
  text-align: center;
  font-size: var(--g-text-base-font-size);
  line-height: var(--g-text-base-line-height);

  & a {
    color: var(--accent-color);
  }
}

.LogoContainer {
  text-align: center;

  & img {
    width: 128px;

    @media (min-width: 768px) {
      width: 256px;
    }
  }
}

.Title {
  text-align: center;
}
