.container {
  display: flex;
  border-radius: 8px;
  margin-bottom: 10px;
  padding: 16px;
  margin-top: 16px;
  background-color: var(--card-background);
  color: var(--card-text);
}

.light {
  border: 1px solid var(--card-border);
}

.message {
  flex: 1;
  margin-right: 16px;
  line-height: 20px;
}
