/**
 * 1. Make the dialog container, and its child overlay spread across
 *    the entire window.
 */
.container,
.overlay {
  position: fixed; /* 1 */
  top: 0; /* 1 */
  right: 0; /* 1 */
  bottom: 0; /* 1 */
  left: 0; /* 1 */
}

/**
  * 1. Make sure the dialog container and all its descendants sits on
  *    top of the rest of the page.
  * 2. Make the dialog container a flex container to easily center the
  *    dialog.
  */
.container {
  z-index: 8; /* 1 */
  display: flex; /* 2 */
}

/**
  * 1. Make sure the dialog container and all its descendants are not
  *    visible and not focusable when it is hidden.
  */
.container[aria-hidden="true"] {
  display: none; /* 1 */
}

/**
  * 1. Make the overlay look like an overlay.
  */
.overlay {
  background-color: rgba(0, 0, 0, 0.5); /* 1 */
  animation: fade-in 200ms both;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
}

@keyframes slide-up {
  from {
    transform: translateY(10%);
  }
}

/**
  * 1. Vertically and horizontally center the dialog in the page.
  * 2. Make sure the dialog sits on top of the overlay.
  * 3. Make sure the dialog has an opaque background.
  */
.content {
  margin: auto; /* 1 */
  z-index: 8; /* 2 */
  position: relative; /* 2 */
  background-color: var(--card-background); /* 3 */
  padding: var(--g-spacing-4);
  border-radius: 8px;
  width: 85vw;
  max-width: 760px;

  animation: fade-in 400ms 200ms both, slide-up 400ms 200ms both;
}

.content p {
  margin: 0;
}

.title.title {
  font-weight: bold;
  font-size: var(--g-text-2xl-font-size);
  line-height: var(--g-text-2xl-line-height);
  margin-bottom: var(--g-spacing-2);
}

.buttonRow {
  margin-top: var(--g-spacing-8);
  display: flex;
  justify-content: flex-end;
}

.buttonRow button {
  margin-left: var(--g-spacing-4);
}
