@keyframes mdc-circular-progress-container-rotate {
  to {
    transform: rotate(360deg);
  }
}

@keyframes mdc-circular-progress-spinner-layer-rotate {
  12.5% {
    transform: rotate(135deg);
  }
  25% {
    transform: rotate(270deg);
  }
  37.5% {
    transform: rotate(405deg);
  }
  50% {
    transform: rotate(540deg);
  }
  62.5% {
    transform: rotate(675deg);
  }
  75% {
    transform: rotate(810deg);
  }
  87.5% {
    transform: rotate(945deg);
  }
  100% {
    transform: rotate(1080deg);
  }
}

@keyframes mdc-circular-progress-left-spin {
  from {
    transform: rotate(265deg);
  }
  50% {
    transform: rotate(130deg);
  }
  to {
    transform: rotate(265deg);
  }
}

@keyframes mdc-circular-progress-right-spin {
  from {
    transform: rotate(-265deg);
  }
  50% {
    transform: rotate(-130deg);
  }
  to {
    transform: rotate(-265deg);
  }
}

.Spinner {
  display: inline-flex;
  position: relative;
  direction: ltr;
  transition: opacity 250ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
}

.circleGraphic,
.container,
.spinnerLayer {
  position: absolute;
  width: 100%;
  height: 100%;
}

.container {
  font-size: 0;
  letter-spacing: 0;
  white-space: nowrap;
  opacity: 0;
}

.circleGraphic {
  fill: transparent !important;
  stroke: var(--accent-color);
}

.gapPatch {
  position: absolute;
  top: 0;
  left: 47.5%;
  box-sizing: border-box;
  width: 5%;
  height: 100%;
  overflow: hidden;
}

.gapPatch .circleGraphic {
  left: -900%;
  width: 2000%;
  transform: rotate(180deg);
}

.circleClipper {
  display: inline-flex;
  position: relative;
  width: 50%;
  height: 100%;
  overflow: hidden;
}

.circleClipper .circleGraphic {
  width: 200%;
}

.circleRight .circleGraphic {
  left: -100%;
}

.indeterminate .container {
  opacity: 1;
  animation: mdc-circular-progress-container-rotate 1568.2352941176ms linear
    infinite;
}

.indeterminate .spinnerLayer {
  animation: mdc-circular-progress-spinner-layer-rotate 5332ms
    cubic-bezier(0.4, 0, 0.2, 1) infinite both;
}

.indeterminate .circleLeft .circleGraphic {
  animation: mdc-circular-progress-left-spin 1333ms cubic-bezier(0.4, 0, 0.2, 1)
    infinite both;
}

.indeterminate .circleRight .circleGraphic {
  animation: mdc-circular-progress-right-spin 1333ms
    cubic-bezier(0.4, 0, 0.2, 1) infinite both;
}
