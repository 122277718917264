.container {
  margin: 0 auto;
  max-width: 960px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";
}

pre {
  padding: 16px;
  border-radius: 8px;
  background-color: #f6f8fa;
  overflow: auto;
}
