.container {
  --container-padding: var(--g-spacing-4);
  --container-margin: var(--g-spacing-4);
  --shipment-name-font-size: var(--g-text-xl-font-size);
  --shipment-name-font-weight: 400;
  --shipment-name-line-height: var(--g-text-xl-line-height);
  --shipment-name-margin-bottom: var(--g-spacing-2);
  --carrier-icon-width: 20px;
  --carrier-icon-margin-right: var(--g-spacing-2);
  --carrier-name-font-size: var(--g-text-sm-font-size);
  --status-line-one-font-size: var(--g-text-sm-font-size);
  --status-line-one-line-height: var(--g-text-sm-line-height);
  --status-line-one-margin-bottom: var(--g-spacing-2);
  --status-line-two-font-size: var(--g-text-sm-font-size);
  --status-line-two-line-height: var(--g-text-sm-line-height);
  --status-line-two-font-weight: 400;
  --countdown-width: 50px;
  --countdown-days-font-size: var(--g-text-5xl-font-size);

  display: flex;
  align-items: center;
  background-color: var(--card-background);
  padding: var(--container-padding);
  border-radius: 8px;
  margin: var(--container-margin) 0;
  transition: background-color 0.15s ease-in-out;
}

.containerLight {
  border: 1px solid var(--card-border);
}

.containerCompact {
  --container-padding: var(--g-spacing-2);
  --container-margin: var(--g-spacing-2);
  --shipment-name-font-size: var(--g-text-lg-font-size);
  --shipment-name-font-weight: 700;
  --shipment-name-line-height: var(--g-text-lg-line-height);
  --shipment-name-margin-bottom: 0px;
  --carrier-icon-width: 20px;
  --carrier-icon-margin-right: var(--g-spacing-2);
  --status-line-one-font-size: var(--g-text-sm-font-size);
  --status-line-one-line-height: var(--g-text-sm-line-height);
  --status-line-one-margin-bottom: 0px;
  --status-line-two-font-size: var(--g-text-sm-font-size);
  --status-line-two-line-height: var(--g-text-sm-line-height);
  --status-line-two-font-weight: 700;
  --countdown-width: 35px;
  --countdown-days-font-size: var(--g-text-3xl-font-size);
}

@media (hover: hover) and (pointer: fine) {
  .link:hover .container {
    background-color: var(--card-background-hover);
  }
}

.link:active .container {
  background-color: var(--card-background-active);
}

.details {
  flex: 1;
  color: var(--card-text);
}

.topLine {
  display: flex;
  align-items: center;
  margin: 0;
  margin-bottom: var(--shipment-name-margin-bottom);
}
.topLine svg {
  width: var(--carrier-icon-width);
  height: auto;
  margin-right: var(--carrier-icon-margin-right);
}

.name {
  font-size: var(--shipment-name-font-size);
  line-height: var(--shipment-name-line-height);
  font-weight: var(--shipment-name-font-weight);
  margin: 0;
  margin-bottom: var(--shipment-name-margin-bottom);
}

.carrierName {
  font-size: var(--carrier-name-font-size);
}

.statusLineOne {
  font-size: var(--status-line-one-font-size);
  line-height: var(--status-line-one-line-height);
  margin: 0;
  margin-bottom: var(--status-line-one-margin-bottom);
}
.statusLineTwo {
  font-size: var(--status-line-two-font-size);
  line-height: var(--status-line-two-line-height);
  font-weight: var(--status-line-two-font-weight);
  margin: 0;
}

.countdown {
  margin-left: 8px;
  color: var(--card-icon);
  text-align: right;
  width: var(--countdown-width);
  line-height: 1;
}

.countdownDays {
  margin: 0;
  font-size: var(--countdown-days-font-size);
  font-weight: 300;
}
.countdownUnits {
  margin: 0;
}

.countdown svg {
  width: 100%;
  height: auto;
  fill: var(--card-icon);
}
