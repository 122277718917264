.Container {
  margin-top: 56px;
  margin-bottom: 80px;
  padding: 0 var(--g-spacing-4);
  flex: 1;

  & a {
    text-decoration: none;
  }
}

.ContainerCompact {
  padding: 0 var(--g-spacing-2);
}

.EmptyState {
  text-align: center;
  color: var(--g-colors-material-grey-400);
  font-weight: normal;
  margin-top: 40vh;
  font-size: var(--g-text-xl-font-size);
}
