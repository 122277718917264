.Container {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 4;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  background-color: var(--action-bar-background);

  & button:hover {
    cursor: pointer;
  }
}

.ActionBarRow {
  height: 56px;
  display: flex;
  position: relative;
  align-items: center;
  box-sizing: border-box;
  width: 100%;

  & svg {
    vertical-align: bottom;
    font-size: 24px;
    fill: var(--action-bar-icon);
  }

  & a,
  & button {
    position: relative;
    padding-top: 0;
    padding-bottom: 0;
    border: none;
    background-color: transparent;

    &:active,
    &:focus {
      outline: none;
    }

    &:disabled {
      cursor: not-allowed;
    }

    &:before {
      content: " ";
      position: absolute;
      top: -8px;
      right: -8px;
      bottom: -8px;
      left: -8px;
      border-radius: 4px;
      transition: background-color 0.15s ease-in-out;
      z-index: -1;
    }

    @media (hover: hover) and (pointer: fine) {
      &:hover {
        &:before {
          background-color: var(--action-bar-button-hover);
        }
      }
    }

    &:active {
      &:before {
        background-color: var(--action-bar-button-active);
      }
    }
  }
}

.ActionBarSection {
  display: inline-flex;
  align-items: flex-start;
  min-width: 0;
  height: 100%;
  z-index: 1;

  padding-left: 16px;
  @supports (padding: max(10px, 10px)) {
    padding-left: max(16px, env(safe-area-inset-left));
  }

  padding-right: 16px;
  @supports (padding: max(10px, 10px)) {
    padding-right: max(16px, env(safe-area-inset-right));
  }
}

.ActionBarStart {
  justify-content: flex-start;
  align-items: center;
  order: -1;
  flex: 1;

  & a,
  & button {
    padding: 0;
    margin-right: 16px;
  }
}

.ActionBarEnd {
  justify-content: flex-end;
  align-items: center;
  order: 1;

  & button,
  & a {
    padding: 0;
    margin-left: 24px;
  }
}

.ActionBarTitle {
  margin-right: 16px;
  font-size: 20px;
  color: var(--action-bar-title);
  font-weight: 500;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
