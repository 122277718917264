.FloatingActionButton {
  display: inline-flex;
  position: fixed;
  justify-content: center;
  align-items: center;
  z-index: 1;
  border: none;
  border-radius: 28px;
  height: 48px;
  background-color: var(--add-shipment-button);
  cursor: pointer;
  transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
  font-size: 0.875rem;
  line-height: 2.25rem;
  font-weight: 500;
  letter-spacing: 0.08929em;
  text-decoration: none;
  padding: 0 20px;

  bottom: 16px;
  @supports (padding: max(10px, 10px)) {
    bottom: max(16px, env(safe-area-inset-bottom));
  }

  @media (max-width: 320px) {
    width: calc(100% - 32px);
  }

  @media (max-width: 1024px) {
    left: 50%;
    transform: translate(-50%, 0);
  }

  @media (min-width: 1025px) {
    right: 16px;
    @supports (padding: max(10px, 10px)) {
      right: max(16px, env(safe-area-inset-right));
    }
  }

  &:hover,
  &:focus {
    // ${props => elevate(8, props.theme.elevationShadow)};
  }

  &:active {
    // ${props => elevate(12, props.theme.elevationShadow)};
  }

  &:active,
  &:focus {
    outline: none;
  }

  & svg {
    margin-left: -8px;
    margin-right: 12px;
    width: 24px;
    height: 24px;
    fill: white;
  }
}

.Text {
  color: white;
}
