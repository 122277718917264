.button {
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .button {
    transition: none;
  }
}
.button:hover {
  color: #212529;
}
.buttonCheck:focus + .button,
.button:focus {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(75, 123, 236, 0.25);
}
.button:disabled,
.button.disabled,
fieldset:disabled .button {
  pointer-events: none;
  opacity: 0.65;
}

.buttonPrimary {
  color: #000;
  background-color: #4b7bec;
  border-color: #4b7bec;
}
.buttonPrimary:hover {
  color: #000;
  background-color: #668fef;
  border-color: #5d88ee;
}
.buttonCheck:focus + .buttonPrimary,
.buttonPrimary:focus {
  color: #000;
  background-color: #668fef;
  border-color: #5d88ee;
  box-shadow: 0 0 0 0.25rem rgba(64, 105, 201, 0.5);
}
.buttonCheck:checked + .buttonPrimary,
.buttonCheck:active + .buttonPrimary,
.buttonPrimary:active,
.buttonPrimary.active,
.show > .buttonPrimary.dropdown-toggle {
  color: #000;
  background-color: #6f95f0;
  border-color: #5d88ee;
}
.buttonCheck:checked + .buttonPrimary:focus,
.buttonCheck:active + .buttonPrimary:focus,
.buttonPrimary:active:focus,
.buttonPrimary.active:focus,
.show > .buttonPrimary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(64, 105, 201, 0.5);
}
.buttonPrimary:disabled,
.buttonPrimary.disabled {
  color: #000;
  background-color: #4b7bec;
  border-color: #4b7bec;
}

.buttonSecondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.buttonSecondary:hover {
  color: #fff;
  background-color: #5c636a;
  border-color: #565e64;
}
.buttonCheck:focus + .buttonSecondary,
.buttonSecondary:focus {
  color: #fff;
  background-color: #5c636a;
  border-color: #565e64;
  box-shadow: 0 0 0 0.25rem rgba(130, 138, 145, 0.5);
}
.buttonCheck:checked + .buttonSecondary,
.buttonCheck:active + .buttonSecondary,
.buttonSecondary:active,
.buttonSecondary.active,
.show > .buttonSecondary.dropdown-toggle {
  color: #fff;
  background-color: #565e64;
  border-color: #51585e;
}
.buttonCheck:checked + .buttonSecondary:focus,
.buttonCheck:active + .buttonSecondary:focus,
.buttonSecondary:active:focus,
.buttonSecondary.active:focus,
.show > .buttonSecondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(130, 138, 145, 0.5);
}
.buttonSecondary:disabled,
.buttonSecondary.disabled {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.buttonSuccess {
  color: #000;
  background-color: #26de81;
  border-color: #26de81;
}
.buttonSuccess:hover {
  color: #000;
  background-color: #47e394;
  border-color: #3ce18e;
}
.buttonCheck:focus + .buttonSuccess,
.buttonSuccess:focus {
  color: #000;
  background-color: #47e394;
  border-color: #3ce18e;
  box-shadow: 0 0 0 0.25rem rgba(32, 189, 110, 0.5);
}
.buttonCheck:checked + .buttonSuccess,
.buttonCheck:active + .buttonSuccess,
.buttonSuccess:active,
.buttonSuccess.active,
.show > .buttonSuccess.dropdown-toggle {
  color: #000;
  background-color: #51e59a;
  border-color: #3ce18e;
}
.buttonCheck:checked + .buttonSuccess:focus,
.buttonCheck:active + .buttonSuccess:focus,
.buttonSuccess:active:focus,
.buttonSuccess.active:focus,
.show > .buttonSuccess.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(32, 189, 110, 0.5);
}
.buttonSuccess:disabled,
.buttonSuccess.disabled {
  color: #000;
  background-color: #26de81;
  border-color: #26de81;
}

.buttonInfo {
  color: #000;
  background-color: #45aaf2;
  border-color: #45aaf2;
}
.buttonInfo:hover {
  color: #000;
  background-color: #61b7f4;
  border-color: #58b3f3;
}
.buttonCheck:focus + .buttonInfo,
.buttonInfo:focus {
  color: #000;
  background-color: #61b7f4;
  border-color: #58b3f3;
  box-shadow: 0 0 0 0.25rem rgba(59, 145, 206, 0.5);
}
.buttonCheck:checked + .buttonInfo,
.buttonCheck:active + .buttonInfo,
.buttonInfo:active,
.buttonInfo.active,
.show > .buttonInfo.dropdown-toggle {
  color: #000;
  background-color: #6abbf5;
  border-color: #58b3f3;
}
.buttonCheck:checked + .buttonInfo:focus,
.buttonCheck:active + .buttonInfo:focus,
.buttonInfo:active:focus,
.buttonInfo.active:focus,
.show > .buttonInfo.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(59, 145, 206, 0.5);
}
.buttonInfo:disabled,
.buttonInfo.disabled {
  color: #000;
  background-color: #45aaf2;
  border-color: #45aaf2;
}

.buttonWarning {
  color: #000;
  background-color: #fed330;
  border-color: #fed330;
}
.buttonWarning:hover {
  color: #000;
  background-color: #feda4f;
  border-color: #fed745;
}
.buttonCheck:focus + .buttonWarning,
.buttonWarning:focus {
  color: #000;
  background-color: #feda4f;
  border-color: #fed745;
  box-shadow: 0 0 0 0.25rem rgba(216, 179, 41, 0.5);
}
.buttonCheck:checked + .buttonWarning,
.buttonCheck:active + .buttonWarning,
.buttonWarning:active,
.buttonWarning.active,
.show > .buttonWarning.dropdown-toggle {
  color: #000;
  background-color: #fedc59;
  border-color: #fed745;
}
.buttonCheck:checked + .buttonWarning:focus,
.buttonCheck:active + .buttonWarning:focus,
.buttonWarning:active:focus,
.buttonWarning.active:focus,
.show > .buttonWarning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(216, 179, 41, 0.5);
}
.buttonWarning:disabled,
.buttonWarning.disabled {
  color: #000;
  background-color: #fed330;
  border-color: #fed330;
}

.buttonDanger {
  color: #000;
  background-color: #fc5c65;
  border-color: #fc5c65;
}
.buttonDanger:hover {
  color: #000;
  background-color: #fc747c;
  border-color: #fc6c74;
}
.buttonCheck:focus + .buttonDanger,
.buttonDanger:focus {
  color: #000;
  background-color: #fc747c;
  border-color: #fc6c74;
  box-shadow: 0 0 0 0.25rem rgba(214, 78, 86, 0.5);
}
.buttonCheck:checked + .buttonDanger,
.buttonCheck:active + .buttonDanger,
.buttonDanger:active,
.buttonDanger.active,
.show > .buttonDanger.dropdown-toggle {
  color: #000;
  background-color: #fd7d84;
  border-color: #fc6c74;
}
.buttonCheck:checked + .buttonDanger:focus,
.buttonCheck:active + .buttonDanger:focus,
.buttonDanger:active:focus,
.buttonDanger.active:focus,
.show > .buttonDanger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(214, 78, 86, 0.5);
}
.buttonDanger:disabled,
.buttonDanger.disabled {
  color: #000;
  background-color: #fc5c65;
  border-color: #fc5c65;
}

.buttonLight {
  color: #000;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.buttonLight:hover {
  color: #000;
  background-color: #f9fafb;
  border-color: #f9fafb;
}
.buttonCheck:focus + .buttonLight,
.buttonLight:focus {
  color: #000;
  background-color: #f9fafb;
  border-color: #f9fafb;
  box-shadow: 0 0 0 0.25rem rgba(211, 212, 213, 0.5);
}
.buttonCheck:checked + .buttonLight,
.buttonCheck:active + .buttonLight,
.buttonLight:active,
.buttonLight.active,
.show > .buttonLight.dropdown-toggle {
  color: #000;
  background-color: #f9fafb;
  border-color: #f9fafb;
}
.buttonCheck:checked + .buttonLight:focus,
.buttonCheck:active + .buttonLight:focus,
.buttonLight:active:focus,
.buttonLight.active:focus,
.show > .buttonLight.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(211, 212, 213, 0.5);
}
.buttonLight:disabled,
.buttonLight.disabled {
  color: #000;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.buttonDark {
  color: #fff;
  background-color: #212529;
  border-color: #212529;
}
.buttonDark:hover {
  color: #fff;
  background-color: #1c1f23;
  border-color: #1a1e21;
}
.buttonCheck:focus + .buttonDark,
.buttonDark:focus {
  color: #fff;
  background-color: #1c1f23;
  border-color: #1a1e21;
  box-shadow: 0 0 0 0.25rem rgba(66, 70, 73, 0.5);
}
.buttonCheck:checked + .buttonDark,
.buttonCheck:active + .buttonDark,
.buttonDark:active,
.buttonDark.active,
.show > .buttonDark.dropdown-toggle {
  color: #fff;
  background-color: #1a1e21;
  border-color: #191c1f;
}
.buttonCheck:checked + .buttonDark:focus,
.buttonCheck:active + .buttonDark:focus,
.buttonDark:active:focus,
.buttonDark.active:focus,
.show > .buttonDark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(66, 70, 73, 0.5);
}
.buttonDark:disabled,
.buttonDark.disabled {
  color: #fff;
  background-color: #212529;
  border-color: #212529;
}

.buttonOutlinePrimary {
  color: #4b7bec;
  border-color: #4b7bec;
}
.buttonOutlinePrimary:hover {
  color: #000;
  background-color: #4b7bec;
  border-color: #4b7bec;
}
.buttonCheck:focus + .buttonOutlinePrimary,
.buttonOutlinePrimary:focus {
  box-shadow: 0 0 0 0.25rem rgba(75, 123, 236, 0.5);
}
.buttonCheck:checked + .buttonOutlinePrimary,
.buttonCheck:active + .buttonOutlinePrimary,
.buttonOutlinePrimary:active,
.buttonOutlinePrimary.active,
.buttonOutlinePrimary.dropdown-toggle.show {
  color: #000;
  background-color: #4b7bec;
  border-color: #4b7bec;
}
.buttonCheck:checked + .buttonOutlinePrimary:focus,
.buttonCheck:active + .buttonOutlinePrimary:focus,
.buttonOutlinePrimary:active:focus,
.buttonOutlinePrimary.active:focus,
.buttonOutlinePrimary.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(75, 123, 236, 0.5);
}
.buttonOutlinePrimary:disabled,
.buttonOutlinePrimary.disabled {
  color: #4b7bec;
  background-color: transparent;
}

.buttonOutlineSecondary {
  color: #6c757d;
  border-color: #6c757d;
}
.buttonOutlineSecondary:hover {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.buttonCheck:focus + .buttonOutlineSecondary,
.buttonOutlineSecondary:focus {
  box-shadow: 0 0 0 0.25rem rgba(108, 117, 125, 0.5);
}
.buttonCheck:checked + .buttonOutlineSecondary,
.buttonCheck:active + .buttonOutlineSecondary,
.buttonOutlineSecondary:active,
.buttonOutlineSecondary.active,
.buttonOutlineSecondary.dropdown-toggle.show {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.buttonCheck:checked + .buttonOutlineSecondary:focus,
.buttonCheck:active + .buttonOutlineSecondary:focus,
.buttonOutlineSecondary:active:focus,
.buttonOutlineSecondary.active:focus,
.buttonOutlineSecondary.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(108, 117, 125, 0.5);
}
.buttonOutlineSecondary:disabled,
.buttonOutlineSecondary.disabled {
  color: #6c757d;
  background-color: transparent;
}

.buttonOutlineSuccess {
  color: #26de81;
  border-color: #26de81;
}
.buttonOutlineSuccess:hover {
  color: #000;
  background-color: #26de81;
  border-color: #26de81;
}
.buttonCheck:focus + .buttonOutlineSuccess,
.buttonOutlineSuccess:focus {
  box-shadow: 0 0 0 0.25rem rgba(38, 222, 129, 0.5);
}
.buttonCheck:checked + .buttonOutlineSuccess,
.buttonCheck:active + .buttonOutlineSuccess,
.buttonOutlineSuccess:active,
.buttonOutlineSuccess.active,
.buttonOutlineSuccess.dropdown-toggle.show {
  color: #000;
  background-color: #26de81;
  border-color: #26de81;
}
.buttonCheck:checked + .buttonOutlineSuccess:focus,
.buttonCheck:active + .buttonOutlineSuccess:focus,
.buttonOutlineSuccess:active:focus,
.buttonOutlineSuccess.active:focus,
.buttonOutlineSuccess.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(38, 222, 129, 0.5);
}
.buttonOutlineSuccess:disabled,
.buttonOutlineSuccess.disabled {
  color: #26de81;
  background-color: transparent;
}

.buttonOutlineInfo {
  color: #45aaf2;
  border-color: #45aaf2;
}
.buttonOutlineInfo:hover {
  color: #000;
  background-color: #45aaf2;
  border-color: #45aaf2;
}
.buttonCheck:focus + .buttonOutlineInfo,
.buttonOutlineInfo:focus {
  box-shadow: 0 0 0 0.25rem rgba(69, 170, 242, 0.5);
}
.buttonCheck:checked + .buttonOutlineInfo,
.buttonCheck:active + .buttonOutlineInfo,
.buttonOutlineInfo:active,
.buttonOutlineInfo.active,
.buttonOutlineInfo.dropdown-toggle.show {
  color: #000;
  background-color: #45aaf2;
  border-color: #45aaf2;
}
.buttonCheck:checked + .buttonOutlineInfo:focus,
.buttonCheck:active + .buttonOutlineInfo:focus,
.buttonOutlineInfo:active:focus,
.buttonOutlineInfo.active:focus,
.buttonOutlineInfo.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(69, 170, 242, 0.5);
}
.buttonOutlineInfo:disabled,
.buttonOutlineInfo.disabled {
  color: #45aaf2;
  background-color: transparent;
}

.buttonOutlineWarning {
  color: #fed330;
  border-color: #fed330;
}
.buttonOutlineWarning:hover {
  color: #000;
  background-color: #fed330;
  border-color: #fed330;
}
.buttonCheck:focus + .buttonOutlineWarning,
.buttonOutlineWarning:focus {
  box-shadow: 0 0 0 0.25rem rgba(254, 211, 48, 0.5);
}
.buttonCheck:checked + .buttonOutlineWarning,
.buttonCheck:active + .buttonOutlineWarning,
.buttonOutlineWarning:active,
.buttonOutlineWarning.active,
.buttonOutlineWarning.dropdown-toggle.show {
  color: #000;
  background-color: #fed330;
  border-color: #fed330;
}
.buttonCheck:checked + .buttonOutlineWarning:focus,
.buttonCheck:active + .buttonOutlineWarning:focus,
.buttonOutlineWarning:active:focus,
.buttonOutlineWarning.active:focus,
.buttonOutlineWarning.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(254, 211, 48, 0.5);
}
.buttonOutlineWarning:disabled,
.buttonOutlineWarning.disabled {
  color: #fed330;
  background-color: transparent;
}

.buttonOutlineDanger {
  color: #fc5c65;
  border-color: #fc5c65;
}
.buttonOutlineDanger:hover {
  color: #000;
  background-color: #fc5c65;
  border-color: #fc5c65;
}
.buttonCheck:focus + .buttonOutlineDanger,
.buttonOutlineDanger:focus {
  box-shadow: 0 0 0 0.25rem rgba(252, 92, 101, 0.5);
}
.buttonCheck:checked + .buttonOutlineDanger,
.buttonCheck:active + .buttonOutlineDanger,
.buttonOutlineDanger:active,
.buttonOutlineDanger.active,
.buttonOutlineDanger.dropdown-toggle.show {
  color: #000;
  background-color: #fc5c65;
  border-color: #fc5c65;
}
.buttonCheck:checked + .buttonOutlineDanger:focus,
.buttonCheck:active + .buttonOutlineDanger:focus,
.buttonOutlineDanger:active:focus,
.buttonOutlineDanger.active:focus,
.buttonOutlineDanger.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(252, 92, 101, 0.5);
}
.buttonOutlineDanger:disabled,
.buttonOutlineDanger.disabled {
  color: #fc5c65;
  background-color: transparent;
}

.buttonOutlineLight {
  color: #f8f9fa;
  border-color: #f8f9fa;
}
.buttonOutlineLight:hover {
  color: #000;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.buttonCheck:focus + .buttonOutlineLight,
.buttonOutlineLight:focus {
  box-shadow: 0 0 0 0.25rem rgba(248, 249, 250, 0.5);
}
.buttonCheck:checked + .buttonOutlineLight,
.buttonCheck:active + .buttonOutlineLight,
.buttonOutlineLight:active,
.buttonOutlineLight.active,
.buttonOutlineLight.dropdown-toggle.show {
  color: #000;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.buttonCheck:checked + .buttonOutlineLight:focus,
.buttonCheck:active + .buttonOutlineLight:focus,
.buttonOutlineLight:active:focus,
.buttonOutlineLight.active:focus,
.buttonOutlineLight.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(248, 249, 250, 0.5);
}
.buttonOutlineLight:disabled,
.buttonOutlineLight.disabled {
  color: #f8f9fa;
  background-color: transparent;
}

.buttonOutlineDark {
  color: #212529;
  border-color: #212529;
}
.buttonOutlineDark:hover {
  color: #fff;
  background-color: #212529;
  border-color: #212529;
}
.buttonCheck:focus + .buttonOutlineDark,
.buttonOutlineDark:focus {
  box-shadow: 0 0 0 0.25rem rgba(33, 37, 41, 0.5);
}
.buttonCheck:checked + .buttonOutlineDark,
.buttonCheck:active + .buttonOutlineDark,
.buttonOutlineDark:active,
.buttonOutlineDark.active,
.buttonOutlineDark.dropdown-toggle.show {
  color: #fff;
  background-color: #212529;
  border-color: #212529;
}
.buttonCheck:checked + .buttonOutlineDark:focus,
.buttonCheck:active + .buttonOutlineDark:focus,
.buttonOutlineDark:active:focus,
.buttonOutlineDark.active:focus,
.buttonOutlineDark.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(33, 37, 41, 0.5);
}
.buttonOutlineDark:disabled,
.buttonOutlineDark.disabled {
  color: #212529;
  background-color: transparent;
}

.buttonLink {
  font-weight: 400;
  color: #4b7bec;
  text-decoration: underline;
}
.buttonLink:hover {
  color: #3c62bd;
}
.buttonLink:disabled,
.buttonLink.disabled {
  color: #6c757d;
}

.buttonLarge {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: 0.3rem;
}

.buttonSmall {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.2rem;
}
